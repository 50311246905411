@import '../../styles/variables.scss';

.spinner-container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $bldr-yellow;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  z-index: 1;
}

.spinner {
  border: 16px solid $bldr-gray;
  border-top-color: $bldr-dark;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
