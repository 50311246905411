@import '../../styles/variables';

.color-picker-container {
  position: absolute;

  .color-picker {
    padding: 8px;
    position: relative;
    display: flex;
    top: 10px;
    left: -10px;
    z-index: 2;
    background-color: $bldr-white;
    border: solid 3px #e3e3e3;

    .color-square {
      margin: 3px;
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      z-index: 3;
      &:hover {
        border-width: 4px;
      }
    }

    &::after {
      content: '';
      height: 16px;
      width: 16px;
      background: #ffffff;
      position: absolute;
      top: -11px;
      left: 8px;
      border: solid 3px #e3e3e3;
      border-bottom: 0;
      border-right: 0;
      transform: rotate(45deg);
      z-index: 1;
    }
  }
}


.color-square {
  box-sizing: content-box;
  display: inline-block;
  padding: 0;
  width: 16px;
  height: 16px;
  outline: none;
  cursor: pointer;

  &--red {
    border: solid 2px $bldr-red;
    background-color: $bldr-red-light;
  }

  &--blue {
    border: solid 2px $bldr-blue;
    background-color: $bldr-blue-light;
  }

  &--violet {
    border: solid 2px $bldr-violet;
    background-color: $bldr-violet-light;
  }

  &--marine {
    border: solid 2px $bldr-marine;
    background-color: $bldr-marine-light;
  }

  &--yellow {
    border: solid 2px $bldr-yellow;
    background-color: $bldr-yellow-light;
  }
}
