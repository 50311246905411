@import '../../styles/variables';
@import '../../styles/mixins';

.board-card {
  @include bldr-card-shadow($bldr-red, 0);
  background-color: $bldr-red-light;
  border-color: $bldr-red;
  border-style: solid;
  border-width: 2px;
  color: $bldr-black;
  margin-bottom: 32px;
  outline: none;
  position: relative;

  &:hover {
    @include bldr-card-shadow($bldr-red, 1);

    .board-card__footer {
      display: flex;
      background: white;
      border-color: $bldr-red;
    }

    .board-card__footer-content--center,
    .board-card__footer-content--right {
      visibility: visible;
    }

    .board-card__btn--unmerge {
      visibility: visible;
    }

    &.board-card--merged {
      .board-card__footer {
        visibility: visible;
      }
    }
  }
  &-withsvg {
    display: flex !important;
    align-items: center;
    color: $bldr-black;
    font-family: $bldr-font-regular;
    font-size: 14px;
    line-height: 1.2;
    & svg {
      margin-left: 5px;
    }
  }
  &__content-editing {
    width: 160px;
    margin: 0 auto;
    display: flex;
    padding-bottom: 8px;
    justify-content: space-between;
    border-bottom: solid 2px #242424;
    & .dots {
      display: flex;
      align-items: center;
      padding-top: 4px;
      & span {
        width: 8px;
        height: 8px;
        background-color: black;
        border-radius: 50%;
        display: inline-block;
        animation-name: dots;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        &:nth-child(2) {
          animation-delay: 0.3s;
          margin-left: 8px;
        }
        &:nth-child(3) {
          animation-delay: 0.5s;
          margin-left: 8px;
        }
      }
    }
    & svg {
      width: 20px;
      height: 20px;
    }
  }

  @keyframes dots {
    20% {
      transform: translateY(0px);
    }
    30% {
      opacity: 0.3;
    }
    50% {
      transform: translateY(-8px);
    }
    60% {
      opacity: 0.6;
    }
    90% {
      opacity: 0.9;
    }
  }

  &--is-being-edited {
    border-style: dashed;
  }

  &--merged {
    .board-card__content {
      .board-card__owner {
        padding-top: 17px;
        width: calc(100% - 60px);
      }
    }

    .board-card__footer {
      @include bldr-card-shadow($bldr-red, 1);
      position: absolute;
      width: calc(100% + 4px);
      transform: translateX(-2px);
      border: 2px solid;
      visibility: hidden;
      z-index: 1;
    }
  }

  &__main-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    position: relative;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
    position: relative;
  }

  &__id {
    color: $bldr-red;
    line-height: 24px;
    display: flex;
    justify-content: flex-start;
    margin-right: 6px;
  }

  &__side {
    display: flex;
    overflow: hidden;
    justify-content: flex-end;
    position: relative;
    width: 100%;

    &::after {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 60px;
    }
  }

  &__content {
    display: block;
    line-height: normal;
    word-break: break-word;

    &-text {
      p {
        margin: 0;
      }

      &:first-child {
        padding-top: 0;
      }
    }

    .board-card__content-divider:last-child {
      display: none;
    }
  }

  &__content-text {
    width: calc(100% - 60px);
  }

  &__content-text {
    font-size: 16px;
    line-height: 1.5;

    @include max-screen($tablet-large) {
      font-size: 14px;
    }
  }

  &__content-divider {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    width: 100%;
    border-top: 2px dashed $bldr-red;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &__btn {
    background: transparent;
    border: 0;
    box-shadow: none;
    display: inline-block;
    outline: 0;
    padding: 0;
    position: relative;

    &:hover {
      cursor: grab;
    }

    &--close {
      margin-right: 6px;
    }
    &--delete {
      cursor: pointer !important;
    }
    &--unmerge {
      visibility: hidden;
    }
  }

  &__votes {
    display: inline-flex;
    align-items: center;
  }

  &__votes-count {
    color: $bldr-dark;
    font-size: 14px;
    line-height: 1.43;
    text-align: right;
    width: 16px;
  }

  &__votes-circle {
    height: 8px;
    width: 8px;
    margin-right: 4px;
    background-color: $bldr-black;
    border-radius: 50%;
  }

  &__footer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 0 20px;
    border-top: 2px solid transparent;
    box-sizing: border-box;
    width: 100%;
  }

  &__footer-content {
    display: flex;
    align-items: center;

    &--center {
      position: absolute;
      left: calc(50% - 22px / 2);
      visibility: hidden;
    }

    &--right {
      visibility: hidden;
      text-align: right;

      .board-card__btn {
        margin-left: 22px;
      }

      .board-card__card-no {
        text-align: center;
        border-bottom: $bldr-black 2px solid;
        width: 10px;
      }
    }
  }

  &__content-textarea {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    border: 2px solid $bldr-red;
    overflow: auto;
    outline: 0;
    resize: none;
    font-family: $bldr-font-normal;
    font-size: 16px;
  }

  &__owner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__owner-name {
    color: $bldr-black;
    font-family: $bldr-font-regular;
    font-size: 14px;
    line-height: 1.2;
    padding-left: 5px;
    padding-top: 2px;
  }

  &__merge-indicator {
    display: flex;
    visibility: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease-in-out;

    &,
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    span,
    svg {
      position: relative;
      z-index: 2;
      margin: 5px 0;
    }

    &::after {
      content: '';
    }

    &--active {
      visibility: visible;
      opacity: 1;

      &::after {
        opacity: 0.7;
      }
    }
  }
}

@each $name, $values in $column-colors {
  .board-card--#{$name} {
    @include bldr-card-shadow(nth($values, 1), 0);
    border-color: nth($values, 1);
    background-color: nth($values, 2);

    &:hover {
      @include bldr-card-shadow(nth($values, 1), 1);

      .board-card__footer {
        border-color: nth($values, 1);
      }
    }

    &.board-card--merged {
      .board-card__footer {
        @include bldr-card-shadow(nth($values, 1), 1);
      }
    }

    .board-card__id {
      color: nth($values, 1);
    }

    .board-card__content-divider {
      border-top: 2px dashed nth($values, 1);
    }

    .board-card__content-textarea {
      border: 2px solid nth($values, 1);
    }

    .board-card__side::after {
      background: nth($values, 2);
      background: linear-gradient(to left, rgba(nth($values, 2), 0) 0%, nth($values, 2) 60%);
    }

    .board-card__merge-indicator::after {
      background-color: nth($values, 1);
    }
  }
}
