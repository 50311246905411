@import '../../styles/variables.scss';

.button {
  height: 44px;
  border: solid 2px $bldr-dark;

  &--white {
    background-color: $bldr-white;
  }
  &--yellow {
    background-color: $bldr-yellow;
  }

  font-family: $bldr-font-bold;
  font-size: 16px;
  letter-spacing: 1px;
  text-align: center;
  color: $bldr-dark;
  padding: 11px 10px 10px;
  box-shadow: -4px 4px 0 0 $bldr-dark;

  &:focus {
    outline: none;
  }

  &:hover:not(:disabled) {
    background-color: $bldr-yellow;
    cursor: pointer;
  }

  &--medium {
    min-width: 100px;
    padding: 10px 20px;
  }

  &--bold {
    font-family: $bldr-font-bold;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;

  }
}
