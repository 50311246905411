@font-face {
  font-family: 'TT Commons Medium';
  font-display: swap;
  src: url('../assets/fonts/TT-Commons-Medium.woff2') format('woff2'),
  url('../assets/fonts/TT-Commons-Medium.woff') format('woff'),
  url('../assets/fonts/TT-Commons-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons Light';
  font-display: swap;
  src: url('../assets/fonts/TT-Commons-Light.woff2') format('woff2'),
  url('../assets/fonts/TT-Commons-Light.woff') format('woff'),
  url('../assets/fonts/TT-Commons-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons Regular';
  font-display: swap;
  src: url('../assets/fonts/TT-Commons-Regular.eot'),
  url('../assets/fonts/TT-Commons-Regular.woff') format('woff'),
  url('../assets/fonts/TT-Commons-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons Bold';
  font-display: swap;
  src: url('../assets/fonts/TT-Commons-Bold.woff2') format('woff2'),
  url('../assets/fonts/TT-Commons-Bold.woff') format('woff'),
  url('../assets/fonts/TT-Commons-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons Black';
  font-display: swap;
  src: url('../assets/fonts/TT-Commons-Black.woff2') format('woff2'),
  url('../assets/fonts/TT-Commons-Black.woff') format('woff'),
  url('../assets/fonts/TT-Commons-Black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
