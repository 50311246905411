@import '../../styles/variables.scss';

.change-value-modal {
  aside {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 28px;
    text-align: center;
    font-family: $bldr-font-bold;
  }

  &__btns-wrapper {
    padding: 15px;
    display: flex;
    justify-content: center;
  }

  &__button {
    max-width: 150px;
    width: 100%;

    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }
}
