@import '../../styles/variables.scss';

.footer {
  background-color: $bldr-dark;
  box-sizing: border-box;
  color: $bldr-white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 20px;
  position: relative;
  width: 100%;

  @media (min-width: $tablet-small) {
    align-items: center;
    flex-direction: row;
  }

  @media (min-width: $tablet-medium) {
    padding: 40px 72px;
  }
}

.footer__container {
  padding-right: 5%;
  padding-bottom: 4%;

  @media (min-width: $tablet-small) {
    padding-right: 4%;
    padding-bottom: 0;
    width: 33%;
  }

  &:last-child {
    padding-bottom: 0;

    @media (min-width: $tablet-small) {
      padding-right: 0;
    }
  }

  &.__space {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 400px;
    width: 100%;

    @media (min-width: $tablet-small) {
      flex-direction: row;
    }

    a {
      padding-right: 5%;
      white-space: nowrap;

      @media (max-width: $tablet-small) {
        font-size: 18px;
      }
    }
  }

  p,
  a {
    font-family: $bldr-font-light;
    line-height: 1.5;
    color: $bldr-white;
    margin: 0;
    text-decoration: none;

    &.text__bold {
      font-family: $bldr-font-bold;
    }
  }
}
