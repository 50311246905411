@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.entry-page {
  align-items: center;
  background-color: $bldr-yellow;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: 1 1;
  min-height: 450px;

  &__btn {
    &--login {
      position: absolute;
      right: 10px;
      top: 20px;

      @include min-screen($tablet-small) {
        position: fixed;
      }
    }
    &--create {
      margin: 2em auto 0;
      width: fit-content;
    }
  }

  &__box {
    background-color: $bldr-yellow;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    padding: 3rem;
    padding-bottom: 2rem;
    max-width: 400px;
    width: 100%;

    @include min-screen($tablet-small) {
      box-shadow: -8px 10px 0 0 $bldr-dark;
      border: 2px solid $bldr-dark;
      max-width: 500px;
    }
  }

  &__title {
    color: $bldr-dark;
    font-family: $bldr-font-bold;
    font-size: 40px;
    margin-top: 0;
    text-align: center;

    @include max-screen($tablet-small) {
      font-size: 2.3em;
    }
  }

  &__links {
    margin-top: 32px;

    a {
      color: $bldr-dark;
      font-family: $bldr-font-bold;
    }
  }
}
