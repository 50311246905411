@mixin min-screen($resolution) {
  @media screen and (min-width: $resolution) {
    @content;
  }
}

@mixin max-screen($resolution) {
  @media screen and (max-width: $resolution - 1) {
    @content;
  }
}

@mixin bldr-card-shadow($color, $opacity) {
  box-shadow: -8px 8px 0px 0px rgba($color, $opacity);
}