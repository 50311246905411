@import '../../styles/variables';

.info-modal {
  background-color: $bldr-gray;
  color: $bldr-dark;

  &__heading {
    font-family: $bldr-font-bold;
    margin-top: 0;
  }

  &__text {
    margin-bottom: 0;
  }
}
