@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.policy-page {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  justify-content: center;
  line-height: 1.5;
  width: 100%;
}

.policy-content {
  width: 100%;
  padding: 40px;

  @include min-screen($tablet-small) {
    padding: 100px;
  }

  &__header {
    margin-bottom: 30px;
    font-size: 30px;
    font-family: $bldr-font-bold;
  }

  &__button-back {
    cursor: pointer;
  }
}

.policy-sublist {
  padding-left: 15px;
  font-size: 16px;
}

.policy-list {
  font-size: 16px;
  padding-left: 15px;

  &__bold {
    font-family: $bldr-font-bold;
  }

  &__header {
    margin-top: 20px;
    font-size: 20px;
    font-family: $bldr-font-bold;
  }
}
