@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.timer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 10px;
  font-size: 2rem;
  font-family: $bldr-font-bold;
  vertical-align: middle;

  @include max-screen($mobile-large) {
    margin: 0;
  }

  &__button {
    margin: 5px;
    padding: 5px;
    min-width: 41px;
    height: 40px;
    font-family: $bldr-font-bold;

    svg {
      display: block;
      margin: auto;
    }

    &--play {
      display: block;
      transform: translate(1px, 1px);
    }
  }

  &__time {
    color: $bldr-dark;
    font-size: 28px;
    font-family: $bldr-font-bold;
    height: 34px;
    min-width: 6rem;
    padding: 5px;
    text-align: center;

    &--warning {
      color: $bldr-red;
    }
  }

  &__input {
    margin: 0 0.5rem;
    max-width: 120px;
    font-family: $bldr-font-bold;
    font-size: 1.6rem;
    text-align: center;
    border-color: $bldr-black;
    border-width: 2px;
    box-shadow: -4px 4px 0 0 #242424;
  }
}
