@import './fonts';
@import './variables';
@import './mixins';

body {
  margin: 0;
  font-family: $bldr-font-normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
}

.field {
  width: 100%;
}

.input-container {
  position: relative;
  margin-bottom: 24px;
  width: 100%;
}

.input {
  background-color: transparent;
  border: 2px solid $bldr-dark;
  box-shadow: none;
  font-size: 1rem;
  height: 45px;
  line-height: 1.5;
  padding: 5px 15px 2px;
  width: 100%;

  &:focus,
  &:valid {
    ~ .label {
      color: $bldr-dark;
      font-size: 12px;
      line-height: 1;
      transform: translateY(-51%);

      &::before {
        background: $bldr-yellow;
        content: '';
        display: block;
        height: 5px;
        left: 50%;
        padding: 0 3px;
        position: absolute;
        top: 50%;
        transform: translateX(-50%);
        width: 102%;
        z-index: -1;
      }
    }
  }

  &:focus {
    ~ .add-vote,
    ~ .remove-vote {
      opacity: 1;
    }
  }

  &[type=number] {
    z-index: 2;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      cursor: pointer;
      height: 45px;
      opacity: 0;
      padding: 22px 20px;
      transform: rotate(90deg);
      width: 45px;
      z-index: 2;
    }

    &:invalid {
      ~ .label {
        font-size: 12px;
        color: black;
        line-height: 1;
        transform: translateY(-51%);

        &::before {
          background: $bldr-yellow;
          content: '';
          display: block;
          height: 5px;
          left: 50%;
          padding: 0 3px;
          position: absolute;
          top: 50%;
          transform: translateX(-50%);
          width: 102%;
          z-index: -1;
        }
      }
    }
  }
}

.input-number-wrapper {
  position: relative;

  .add-vote,
  .remove-vote {
    background-color: $bldr-yellow;
    border: solid 2px $bldr-dark;
    color: $bldr-dark;
    cursor: pointer;
    font-size: 40px;
    height: 45px;
    line-height: 45px;
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
    text-align: center;
    width: 45px;

    svg {
      height: 20px;
      width: 16px;
    }
  }

  .add-vote {
    border-left: 0;
    right: 0;
    top: 0;

    svg {
      transform: rotate(180deg) translateY(-3px);
    }
  }

  .remove-vote {
    right: 45px;
    top: 0;

    svg {
      transform: translateY(-1px);
    }
  }
}

.label {
  position: absolute;
  top: 0;
  left: 17px;
  line-height: 49px;
  transition: all 0.2s ease;
  user-select: none;
}
