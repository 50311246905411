@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.header {
  align-items: center;
  background-color: $bldr-yellow;
  display: flex;
  justify-content: space-between;
  min-height: 80px;
  padding: 0 20px;
  position: relative;

  @include min-screen($tablet-small) {
    padding: 0 40px;
  }

  @include min-screen($desktop) {
    padding: 0 72px;
  }

  &__board-name {
    color: $bldr-dark;
    font-family: $bldr-font-bold;
    font-size: 32px;
    line-height: 32px;
    margin: 0 auto;
    text-align: center;
    transform: translateY(2px);
    width: 78%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include min-screen($tablet-small) {
      width: 62%;
    }

    @include min-screen($desktop) {
      width: 80%;
    }
  }

  &__settings-options {
    position: relative;
  }

  &__settings-container--right {
    display: flex;
    position: absolute;
    right: 20px;

    @include min-screen($tablet-small) {
      right: 26px;
    }
    @include min-screen($desktop) {
      right: 58px;
    }

    .button-settings {
      .arrow-icon {
        padding-left: 15px;

        &.open {
          svg {
            transform: rotate(180deg);
            transform-origin: center 60%;
          }
        }
      }
    }
  }

  &__settings-container--left {
    cursor: pointer;
    left: 10px;
    position: absolute;
    padding: 10px;
    z-index: 10;

    @include min-screen($tablet-small) {
      left: 30px;
    }
    @include min-screen($desktop) {
      left: 62px;
    }

    &.board-column__icon-btn {
      margin-left: 0;
    }
  }
}
