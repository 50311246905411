@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.top-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 80px;
  padding: 0 20px;
  background-color: $bldr-blue;

  @include min-screen($tablet-small) {
    padding: 0 40px;
  }

  @include min-screen($desktop) {
    padding: 0 72px;
  }

  &__button {
    margin-bottom: 15px;
    white-space: nowrap;

    @include min-screen($tablet-small) {
      margin-bottom: unset;
    }
  }

  &__title {
    font-family: $bldr-font-bold;
  }
}

.text-container {
  margin-top: 10px;
  margin-right: 30px;
  margin-bottom: 10px;
  color: $bldr-dark;
  font-family: $bldr-font-regular;
  font-size: 16px;
  line-height: 16px;

  @include min-screen($tablet-small) {
    margin-top: unset;
    margin-bottom: unset;
  }
}
