@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.header-container {
  position: absolute;
  right: clamp(5px, 3vw, 68px);
  padding: 10px;
}

.settings-options {
  z-index: 100;
  position: absolute;
  right: 0;
  padding: 0;
  top: 80px;
  width: 100%;

  @include min-screen($tablet-small) {
    top: unset;
    width: unset;
  }

  .settings-option {
    height: 38px;
    background-color: $bldr-white;
    border: solid 2px $bldr-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: $bldr-font-bold;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: center;
    color: $bldr-dark;
    padding-top: 4px;
    width: 100%;

    @media (min-width: $tablet-small) {
      width: 200px;
    }

    &--disabled {
      color: $bldr-dark-light;;
    }

    &:hover {
      background-color: $bldr-yellow;
      cursor: pointer;
    }

    &:not(:first-child) {
      border-top-width: 1px;
    }

    &:not(:last-child) {
      border-bottom-width: 1px;
    }
  }
}

.settings {
  &__votes-number-hint {
    text-align: center;
  }

  &__modal-input {
    height: 2rem;
    margin-left: 15%;
    margin-right: 15%;
    font-family: $bldr-font-bold;
    font-size: 1.5rem;
    text-align: center;
    border-color: $bldr-black;
    border-width: 2px;
  }

  &__info-modal {
    left: unset;
    top: 90px;
    right: 140px;
    max-width: 300px;
    transform: unset;

    @include min-screen($desktop) {
      right: 172px;
    }
  }

  &__login-wrapper {
    background-color: $bldr-yellow;
  }

  &__login-wrapper--active {
    z-index: 9001;
  }

  &__button {
    padding: 8px 15px 12px 19px;
  }

  &__login-mobile {
    position: absolute;
    width: calc(100% - 65px);
    left: 0%;
   }
}
