@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.board-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: $tablet-small) {
    flex: 1 1;
    height: unset;
  }

  button.columns__scroll-button  {
    top: 50%;
    position: absolute;
    padding-top: 80px;
    background-color: transparent;
    padding: 30px;
    border: 0;
    outline: 0;

    &--right {
      right: 5px;

      span {
        right: 8px;
        border-right: 1px solid $bldr-black;
        border-bottom: 1px solid $bldr-black;
      }

      span:nth-of-type(2) {
        right: 24px;
      }

      span:nth-of-type(3) {
        right: 40px;
      }

      @include max-screen($desktop){
        right: 0;

        span:nth-of-type(2) {
          right: 18px;
        }

        span:nth-of-type(3) {
          right: 28px;
        }
      }
    }

    &--left {
      left: 5px;

      span {
        left: 8px;
        border-left: 1px solid $bldr-black;
        border-top: 1px solid $bldr-black;
      }

      span:nth-of-type(2) {
        left: 24px;
      }

      span:nth-of-type(3) {
        left: 40px;
      }

      @include max-screen($desktop){
        left: 0;
        span:nth-of-type(2) {
          left: 18px;
        }

        span:nth-of-type(3) {
          left: 28px;
        }
      }
    }

    span {
      position: absolute;
      top: 20px;
      margin-top: -20px;
      width: 20px;
      height: 20px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-animation: sdb 2s infinite;
      animation: sdb 2s infinite;
      opacity: 0;
      box-sizing: border-box;

      @include max-screen($desktop){
        width: 15px;
        height: 15px;
      }
    }

    span:nth-of-type(1) {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }

    span:nth-of-type(2) {
      -webkit-animation-delay: .15s;
      animation-delay: .15s;
    }

    span:nth-of-type(3) {
      -webkit-animation-delay: .3s;
      animation-delay: .3s;
    }
  }


  @-webkit-keyframes sdb {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes sdb {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
