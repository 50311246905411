@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.top-bar {
  background-color: $bldr-gray;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 20px;

  @include min-screen($tablet-small) {
    padding: 0 40px;
  }

  @include min-screen($desktop) {
    padding: 0 72px;
  }

  &--mobile {
    display: flex;
    flex-direction: row;

    &__container {
      align-items: center;
    }
  }

  &__container {
    &--left {
      justify-content: flex-start;
      min-width: 33vw;
    }

    &--middle {
      justify-content: center;
      flex-grow: 1;
    }

    &--right {
      flex-grow: 0;
      flex-shrink: 1;
      justify-content: flex-end;

      @media (min-width: 1485px) {
        min-width: 33vw;
      }

      .top-bar__button {
        margin-right: 0;
      }
    }

    margin: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  &__button {
    margin-right: 12px;
    max-height: 40px;
    height: auto;
    flex-shrink: 0;
    font-size: 14px;

    &--sort {
      .top-bar__icons {
        padding-left: 4px;
        display: inline-block;

        svg {
          transform: rotate(90deg);
          transform-origin: center 60%;
          width: 12px;
        }
      }
    }
  }
}

.top-bar-options {
  display: block;
  width: 100vw;
  z-index: 100;

  @media (min-width: 1400px) {
    position: absolute;
  }

  .top-bar-option {
    align-items: center;
    background-color: $bldr-white;
    border: solid 2px $bldr-dark;
    color: $bldr-dark;
    display: flex;
    font-family: $bldr-font-bold;
    font-size: 16px;
    height: 38px;
    justify-content: center;
    letter-spacing: 1px;
    line-height: 1.5;
    padding-top: 4px;
    text-align: center;
    width: 100vw;

    &:hover {
      background-color: $bldr-yellow;
      cursor: pointer;
    }

    &:not(:first-child) {
      border-top-width: 1px;
    }

    &:not(:last-child) {
      border-bottom-width: 1px;
    }
  }
}

.topbar-button--settings {
  background-color: $bldr-gray;
  border: none;
  color: $bldr-dark;
  font-family: $bldr-font-bold;
  font-size: 16px;
  height: 50px;
  line-height: 1.5;
  outline: 0px;
  text-align: center;
  width: 100vw;

  &.open {
    svg {
      transform: rotate(180deg);
      transform-origin: center 60%;
    }
  }
}
