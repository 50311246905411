@import '~react-toastify/dist/ReactToastify.css';
@import '../../styles/variables.scss';

.Toastify {
  &__toast {
    min-height: 3rem;

    @media (max-width: $tablet-small) {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &--info {
      background-color: $bldr-blue !important;
      color: $bldr-white !important;
    }

    &--warning {
      background-color: $bldr-marine !important;
      color: $bldr-white !important;
    }

    &--error {
      background-color: $bldr-red !important;
      color: $bldr-white !important;
    }
  }

  &__close-button {
    color: $bldr-black !important;
  }
}
