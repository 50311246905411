@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.archive-page {
  background-color: $bldr-gray;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    margin-top: 35px;
    font-size: 20px;
    font-family: $bldr-font-bold;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 75px;

    &__addFolder {
      display: flex;
      max-width: 460px;
      width: 100%;
      padding: 0 20px;
      flex-direction: column;

      @include min-screen($tablet-small) {
        padding: 0;
        flex-direction: row;
      }

      .input-container {
        margin-right: 10px;

        .input ~ label::before {
          background-color: $bldr-gray;
        }
      }

      .button {
        white-space: nowrap;
      }
    }
  }

  &__boardContainer {
    margin-top: 25px;
    width: 70%;

    button {
      float: right;
    }
  }
}
