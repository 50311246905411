@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.progress-bar {
  font-family: $bldr-font-regular;
  position: relative;
  padding: 0 20px;

  @include min-screen($tablet-small) {
    padding: 0 40px;
  }

  @include min-screen($desktop) {
    padding: 0 72px;
  }

  progress {
    width: 100%;
    height: 16px;
    appearance: none;
    border: none;
    color: $bldr-yellow; // IE
  }

  /* Chrome */
  progress[value]::-webkit-progress-bar {
    background-color: $bldr-dark-gray;
  }

  progress[value]::-webkit-progress-value {
    background-color: $bldr-yellow;
  }

  /* Firefox */
  progress[value]::-moz-progress-bar {
    background-color: $bldr-yellow;
  }

  span {
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 14px;
    color: $bldr-dark;
  }
}
