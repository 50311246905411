// Colors
$bldr-yellow: #f1d624;
$bldr-black: #000;
$bldr-white: #fff;
$bldr-blue: #aac5da;
$bldr-marine: #48c5b5;
$bldr-dark: #242424;
$bldr-dark-light: rgba(36,36,36,0.3);
$bldr-gray: #f0f0f0;
$bldr-dark-gray: #dbdbdb;
$bldr-violet: #6652e4;
$bldr-red: #f57474;

$bldr-blue-light: #e5edf3;
$bldr-violet-light: #e0dcfa;
$bldr-red-light: #fcd5d5;
$bldr-marine-light: #c8ede9;
$bldr-yellow-light: #faf2be;


$color-base: (
  'yellow': $bldr-yellow,
  'black': $bldr-black,
  'white': $bldr-white,
  'blue': $bldr-blue,
  'marine': $bldr-marine,
  'dark': $bldr-dark,
  'gray': $bldr-gray,
  'violet': $bldr-violet,
  'red': $bldr-red,
);

// Column color list: (background, font color)
$column-colors: (
  'blue': (
    $bldr-blue,
    $bldr-blue-light,
  ),
  'red': (
    $bldr-red,
    $bldr-red-light,
  ),
  'violet': (
    $bldr-violet,
    $bldr-violet-light,
  ),
  'yellow': (
    $bldr-yellow,
    $bldr-yellow-light,
  ),
  'marine': (
    $bldr-marine,
    $bldr-marine-light,
  ),
  'default': (
    $bldr-red,
    $bldr-red-light,
  ),
);

// Fonts
$bldr-font-light: 'TT Commons Light', Helvetica, Arial;
$bldr-font-normal: 'TT Commons Medium', Helvetica, Arial;
$bldr-font-regular: 'TT Commons Regular', Helvetica, Arial;
$bldr-font-bold: 'TT Commons Bold', Helvetica, Arial;

// Breakpoints
$mobile-small: 350px;
$mobile-medium: 385px;
$mobile-large: 480px;

$tablet-small: 768px;
$tablet-medium: 992px;
$tablet-large: 1024px;

$desktop: 1200px;

// Shadows
$shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
$shadow-dark: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.15);

// border colors
$bldr-border-gray: #e3e3e3;


$footer-min-height: 120px;
