.confirmation-modal {
  &__title {
    font-size: 20px;
    text-align: center;
  }

  &__btns-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__button {
    max-width: 150px;
    width: 100%;
    
    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }
}