@import '../../styles/mixins';
@import '../../styles/variables';

.board {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 500px;

  &__header {
    display: block;
    padding: 32px 0 15px;
  }

  &__body {
    display: flex;
    flex-direction: row;
    margin: 0;
    scroll-behavior: smooth;
    overflow-x: auto;
    flex: 1;

    @include min-screen($tablet-small) {
      margin: 0 40px 16px;
    }

    @include min-screen($desktop) {
      margin: 0 58px 32px;
    }
  }
}
