@import '../../styles/variables';
@import '../../styles/mixins';

.modal {
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: $bldr-yellow;
  border-width: 2px;
  border-radius: 0;
  outline: none;
  border-style: solid;
  border-color: $bldr-black;

  @include max-screen($tablet-small) {
    width: 85%;
    margin: 20px 0;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 18px;
    transition: 0.05s transform ease-in-out;

    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
}
