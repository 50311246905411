@import '../../styles/variables';
@import '../../styles/mixins';

.board-column {
  display: block;
  width: 20vw;
  min-width: 400px;
  max-width: 35vw;
  width: 100%;
  border: 3px solid transparent;
  margin-left: 3px;
  margin-right: 3px;

  @include max-screen($desktop) {
    min-width: 352px;
  }

  @include max-screen(600px) {
    min-width: 85%;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:first-child:nth-last-child(n + 4) {
    ~ .board-column {
      &:nth-child(3) {
        margin-right: 3px;
      }
    }
  }

  &--active {
    border-color: $bldr-border-gray;

    .board-column__topbar {
      border-color: $bldr-border-gray;
    }
  }

  &__topbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    border-bottom: 3px solid transparent;

    @include max-screen(600px) {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &__topbar-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--left {
      @include max-screen(600px) {
        width: 100%;
        justify-content: space-between;
      }
    }

    &--right {
      @include max-screen(600px) {
        margin-top: 15px;
      }
    }
  }

  &__topbar-content-title-wrapper {
    position: relative;
    display: flex;
  }

  &__title {
    font-family: $bldr-font-bold;
    font-size: 22px;
    line-height: 1.2;
    text-align: left;
    color: $bldr-dark;
    margin: 0;
    margin-left: 30px;

    &-input {
      margin-left: 30px;
    }
  }

  &__icon-btn {
    margin-left: 22px;
    border: 0;
    outline: 0;
    box-shadow: none;
    background: transparent;
    padding: 0;

    @include max-screen($desktop) {
      margin-left: 10px;
    }

    &:hover {
      cursor: pointer;
    }

    &--openclose {
      margin-left: 35px;

      @include max-screen($desktop) {
        margin-left: 25px;
      }
    }
  }

  &__btn-wrapper {
    padding: 15px 10px;
  }

  &__btn-bottom {
    padding: 0;
  }

  &__btn {
    display: block;
    background-color: $bldr-gray;
    border: 0;
    outline: 0;
    width: 100%;
    box-shadow: none;
    padding: 20px 25px;
    font-size: 16px;
    font-family: $bldr-font-bold;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: center;
    color: $bldr-dark;

    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    padding: 15px 10px 23px;
  }
}
